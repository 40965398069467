export const text = {
    "heading": {
        "xSmall": "var(--textHeadingXSmall)",
        "small": "var(--textHeadingSmall)",
        "medium": "var(--textHeadingMedium)",
        "large": "var(--textHeadingLarge)",
        "xLarge": "var(--textHeadingXLarge)"
    },
    "regular": {
        "default": {
            "xSmall": "var(--textRegularDefaultXSmall)",
            "small": "var(--textRegularDefaultSmall)",
            "medium": "var(--textRegularDefaultMedium)",
            "large": "var(--textRegularDefaultLarge)"
        },
        "bold": {
            "xSmall": "var(--textRegularBoldXSmall)",
            "small": "var(--textRegularBoldSmall)",
            "medium": "var(--textRegularBoldMedium)",
            "large": "var(--textRegularBoldLarge)"
        }
    }
} as const;
export const fontFamily = {
    "default": "var(--fontFamilyDefault)",
    "code": "var(--fontFamilyCode)"
} as const;
