import { css } from "@emotion/css";
import { themeTokens, space, text } from "@octopusdeploy/design-system-tokens";
import React, { Fragment } from "react";
import type { DesignSystemLinkHref } from "../../routing/OctopusRoutingContext";
import { useOctopusLinkComponent } from "../../routing/OctopusRoutingContext";
import { ArrowLeftIcon } from "../Icon/index";
export interface BreadcrumbItem {
    /** The label of a breadcrumb link */
    label: string;
    /** The URL of the page that the breadcrumb routes to, when the value is null, the label will be shown as plain text instead of link */
    pageUrl: DesignSystemLinkHref | null;
}
export interface BreadcrumbsProps {
    /** Items to be combined with Slash to form breadcrumbs */
    items: BreadcrumbItem[];
    /** If true, apply back icon at the beginning of the breadcrumbs */
    showBackIcon?: boolean;
}
export function Breadcrumbs({ items, showBackIcon }: BreadcrumbsProps) {
    const lastItemIndex = items.length - 1;
    return (<nav>
            <ol className={breadcrumbsStyles}>
                {items.map((item, index) => (<Fragment key={index}>
                        <li>
                            <Breadcrumb showBackIcon={!!showBackIcon && index === 0} label={item.label} pageUrl={item.pageUrl}/>
                        </li>
                        {index !== lastItemIndex && (<li>
                                <span role={"separator"} className={separatorStyles}>
                                    /
                                </span>
                            </li>)}
                    </Fragment>))}
            </ol>
        </nav>);
}
interface BreadcrumbProps extends BreadcrumbItem {
    showBackIcon: boolean;
}
function Breadcrumb({ label, pageUrl, showBackIcon }: BreadcrumbProps) {
    const Link = useOctopusLinkComponent();
    const content = (<>
            {showBackIcon && <ArrowLeftIcon />}
            <span>{label}</span>
        </>);
    if (pageUrl === null) {
        return content;
    }
    return (<Link className={breadcrumbItemStyles} href={pageUrl}>
            {content}
        </Link>);
}
const breadcrumbsStyles = css({
    listStyle: "none",
    font: text.regular.default.small,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    margin: 0,
    padding: 0,
    gap: space["8"],
});
const separatorStyles = css({
    userSelect: "none",
    color: themeTokens.color.text.tertiary,
});
const breadcrumbItemStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space["4"],
    textDecoration: "none",
    color: themeTokens.color.text.link.default,
});
