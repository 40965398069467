import { css, cx } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { useIsLargerThanIpadResolution } from "../../hooks/breakpoints";
import { resetStyles } from "../../utils/resetStyles";
import { MenuIcon } from "../Icon";
import { NavigationBarIconButton } from "../NavigationBar/NavigationBarIconButton";
interface TopNavBarProps {
    logo?: React.ReactElement;
    searchBar?: React.ReactElement;
    actions?: TopNavBarActionData[];
    onExpandMenu: () => void;
}
export interface TopNavBarActionData {
    key: string;
    content: React.ReactElement;
}
export function TopNavBar({ logo, searchBar, actions, onExpandMenu }: TopNavBarProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const actionsComponent = actions && <TopNavBarActions actions={actions}/>;
    if (isLargerThanIpad) {
        return (<nav className={topNavBarStyles}>
                <TopNavBarRow>
                    {logo}
                    {searchBar}
                    {actionsComponent}
                </TopNavBarRow>
            </nav>);
    }
    return (<nav className={topNavBarStyles}>
            <TopNavBarRow>
                {<NavigationBarIconButton icon={<MenuIcon />} accessibleName="Open navigation menu" onClick={onExpandMenu}/>}
                {logo}
                {actionsComponent}
            </TopNavBarRow>
            {searchBar && (<TopNavBarRow>
                    <div className={searchBarMobileContainerStyles}>{searchBar}</div>
                </TopNavBarRow>)}
        </nav>);
}
function TopNavBarRow({ children }: React.PropsWithChildren<{}>) {
    return <div className={cx(topNavBarRowStyles)}>{children}</div>;
}
function TopNavBarActions({ actions }: {
    actions?: TopNavBarActionData[];
}) {
    return (<div className={cx(topNavBarActionsContainerStyles)}>
            <ul className={actionsListStyles}>
                {actions?.map((action) => {
            if (!action.content) {
                return null;
            }
            return <li key={action.key}>{action.content}</li>;
        })}
            </ul>
        </div>);
}
const topNavBarStyles = css({
    position: "relative",
    padding: space[16],
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: space[16],
});
const topNavBarRowStyles = css({
    display: "flex",
    //We need an explicit height in order to avoid items/actions from expanding
    //the navbar past what the design accounts for.
    height: "2.25rem",
    gap: space[16],
});
const searchBarMobileContainerStyles = css({
    flex: 1,
});
const topNavBarActionsContainerStyles = css({
    marginLeft: "auto",
});
const actionsListStyles = css({
    ...resetStyles.ul,
    display: "flex",
    alignItems: "center",
    gap: space[16],
});
