export const color = {
    "callout": {
        "text": {
            "info": "var(--colorCalloutTextInfo)",
            "success": "var(--colorCalloutTextSuccess)",
            "warning": "var(--colorCalloutTextWarning)",
            "danger": "var(--colorCalloutTextDanger)",
            "feature": "var(--colorCalloutTextFeature)",
            "default": "var(--colorCalloutTextDefault)",
            "body": "var(--colorCalloutTextBody)"
        },
        "icon": {
            "info": "var(--colorCalloutIconInfo)",
            "success": "var(--colorCalloutIconSuccess)",
            "warning": "var(--colorCalloutIconWarning)",
            "danger": "var(--colorCalloutIconDanger)",
            "feature": "var(--colorCalloutIconFeature)",
            "default": "var(--colorCalloutIconDefault)"
        },
        "border": {
            "info": "var(--colorCalloutBorderInfo)",
            "success": "var(--colorCalloutBorderSuccess)",
            "warning": "var(--colorCalloutBorderWarning)",
            "danger": "var(--colorCalloutBorderDanger)",
            "feature": "var(--colorCalloutBorderFeature)",
            "default": "var(--colorCalloutBorderDefault)"
        },
        "background": {
            "info": {
                "default": "var(--colorCalloutBackgroundInfoDefault)",
                "hover": "var(--colorCalloutBackgroundInfoHover)",
                "pressed": "var(--colorCalloutBackgroundInfoPressed)"
            },
            "success": {
                "default": "var(--colorCalloutBackgroundSuccessDefault)",
                "hover": "var(--colorCalloutBackgroundSuccessHover)",
                "pressed": "var(--colorCalloutBackgroundSuccessPressed)"
            },
            "attention": {
                "default": "var(--colorCalloutBackgroundAttentionDefault)",
                "hover": "var(--colorCalloutBackgroundAttentionHover)",
                "pressed": "var(--colorCalloutBackgroundAttentionPressed)"
            },
            "danger": {
                "default": "var(--colorCalloutBackgroundDangerDefault)",
                "hover": "var(--colorCalloutBackgroundDangerHover)",
                "pressed": "var(--colorCalloutBackgroundDangerPressed)"
            },
            "feature": {
                "default": "var(--colorCalloutBackgroundFeatureDefault)",
                "hover": "var(--colorCalloutBackgroundFeatureHover)",
                "pressed": "var(--colorCalloutBackgroundFeaturePressed)"
            },
            "default": {
                "default": "var(--colorCalloutBackgroundDefaultDefault)",
                "hover": "var(--colorCalloutBackgroundDefaultHover)",
                "pressed": "var(--colorCalloutBackgroundDefaultPressed)"
            }
        }
    },
    "menuList": {
        "text": {
            "primary": "var(--colorMenuListTextPrimary)",
            "secondary": "var(--colorMenuListTextSecondary)",
            "highlight": "var(--colorMenuListTextHighlight)"
        },
        "background": {
            "default": "var(--colorMenuListBackgroundDefault)",
            "hover": "var(--colorMenuListBackgroundHover)",
            "active": "var(--colorMenuListBackgroundActive)"
        },
        "icon": {
            "primary": "var(--colorMenuListIconPrimary)"
        },
        "border": {
            "primary": "var(--colorMenuListBorderPrimary)"
        }
    },
    "textField": {
        "text": {
            "primary": "var(--colorTextFieldTextPrimary)",
            "secondary": "var(--colorTextFieldTextSecondary)",
            "selected": "var(--colorTextFieldTextSelected)",
            "danger": "var(--colorTextFieldTextDanger)",
            "disabled": "var(--colorTextFieldTextDisabled)"
        },
        "border": {
            "default": "var(--colorTextFieldBorderDefault)",
            "hover": "var(--colorTextFieldBorderHover)",
            "active": "var(--colorTextFieldBorderActive)",
            "danger": "var(--colorTextFieldBorderDanger)",
            "disabled": "var(--colorTextFieldBorderDisabled)"
        },
        "icon": {
            "primary": "var(--colorTextFieldIconPrimary)",
            "disabled": "var(--colorTextFieldIconDisabled)"
        }
    },
    "chip": {
        "filled": {
            "text": {
                "primary": "var(--colorChipFilledTextPrimary)",
                "secondary": "var(--colorChipFilledTextSecondary)",
                "info": "var(--colorChipFilledTextInfo)",
                "success": "var(--colorChipFilledTextSuccess)",
                "warning": "var(--colorChipFilledTextWarning)",
                "danger": "var(--colorChipFilledTextDanger)"
            },
            "background": {
                "primary": "var(--colorChipFilledBackgroundPrimary)",
                "secondary": "var(--colorChipFilledBackgroundSecondary)",
                "info": "var(--colorChipFilledBackgroundInfo)",
                "success": "var(--colorChipFilledBackgroundSuccess)",
                "warning": "var(--colorChipFilledBackgroundWarning)",
                "danger": "var(--colorChipFilledBackgroundDanger)"
            },
            "icon": {
                "primary": "var(--colorChipFilledIconPrimary)",
                "secondary": "var(--colorChipFilledIconSecondary)",
                "info": "var(--colorChipFilledIconInfo)",
                "success": "var(--colorChipFilledIconSuccess)",
                "warning": "var(--colorChipFilledIconWarning)",
                "danger": "var(--colorChipFilledIconDanger)"
            }
        },
        "outlined": {
            "border": {
                "primary": "var(--colorChipOutlinedBorderPrimary)",
                "secondary": "var(--colorChipOutlinedBorderSecondary)",
                "info": "var(--colorChipOutlinedBorderInfo)",
                "success": "var(--colorChipOutlinedBorderSuccess)",
                "warning": "var(--colorChipOutlinedBorderWarning)",
                "danger": "var(--colorChipOutlinedBorderDanger)"
            },
            "text": {
                "primary": "var(--colorChipOutlinedTextPrimary)",
                "secondary": "var(--colorChipOutlinedTextSecondary)",
                "info": "var(--colorChipOutlinedTextInfo)",
                "success": "var(--colorChipOutlinedTextSuccess)",
                "warning": "var(--colorChipOutlinedTextWarning)",
                "danger": "var(--colorChipOutlinedTextDanger)"
            },
            "icon": {
                "primary": "var(--colorChipOutlinedIconPrimary)",
                "secondary": "var(--colorChipOutlinedIconSecondary)",
                "info": "var(--colorChipOutlinedIconInfo)",
                "success": "var(--colorChipOutlinedIconSuccess)",
                "warning": "var(--colorChipOutlinedIconWarning)",
                "danger": "var(--colorChipOutlinedIconDanger)"
            }
        }
    },
    "button": {
        "background": {
            "primary": {
                "default": "var(--colorButtonBackgroundPrimaryDefault)",
                "hover": "var(--colorButtonBackgroundPrimaryHover)",
                "pressed": "var(--colorButtonBackgroundPrimaryPressed)"
            },
            "secondary": {
                "default": "var(--colorButtonBackgroundSecondaryDefault)",
                "hover": "var(--colorButtonBackgroundSecondaryHover)",
                "pressed": "var(--colorButtonBackgroundSecondaryPressed)"
            },
            "tertiary": {
                "default": "var(--colorButtonBackgroundTertiaryDefault)",
                "hover": "var(--colorButtonBackgroundTertiaryHover)",
                "pressed": "var(--colorButtonBackgroundTertiaryPressed)"
            },
            "destructive": {
                "default": "var(--colorButtonBackgroundDestructiveDefault)",
                "hover": "var(--colorButtonBackgroundDestructiveHover)",
                "pressed": "var(--colorButtonBackgroundDestructivePressed)"
            },
            "quiet": {
                "default": "var(--colorButtonBackgroundQuietDefault)",
                "hover": "var(--colorButtonBackgroundQuietHover)",
                "pressed": "var(--colorButtonBackgroundQuietPressed)"
            },
            "loud": {
                "default": "var(--colorButtonBackgroundLoudDefault)",
                "hover": "var(--colorButtonBackgroundLoudHover)",
                "pressed": "var(--colorButtonBackgroundLoudPressed)"
            },
            "neutral": {
                "default": "var(--colorButtonBackgroundNeutralDefault)",
                "hover": "var(--colorButtonBackgroundNeutralHover)",
                "pressed": "var(--colorButtonBackgroundNeutralPressed)"
            },
            "disabled": "var(--colorButtonBackgroundDisabled)"
        },
        "text": {
            "primary": "var(--colorButtonTextPrimary)",
            "secondary": "var(--colorButtonTextSecondary)",
            "tertiary": "var(--colorButtonTextTertiary)",
            "destructive": "var(--colorButtonTextDestructive)",
            "quiet": "var(--colorButtonTextQuiet)",
            "loud": "var(--colorButtonTextLoud)",
            "neutral": "var(--colorButtonTextNeutral)",
            "disabled": "var(--colorButtonTextDisabled)"
        },
        "border": {
            "secondary": "var(--colorButtonBorderSecondary)",
            "disabled": "var(--colorButtonBorderDisabled)"
        },
        "icon": {
            "primary": "var(--colorButtonIconPrimary)",
            "secondary": "var(--colorButtonIconSecondary)",
            "tertiary": "var(--colorButtonIconTertiary)",
            "destructive": "var(--colorButtonIconDestructive)",
            "quiet": "var(--colorButtonIconQuiet)",
            "loud": "var(--colorButtonIconLoud)",
            "neutral": "var(--colorButtonIconNeutral)",
            "disabled": "var(--colorButtonIconDisabled)"
        }
    },
    "dialog": {
        "background": {
            "primary": "var(--colorDialogBackgroundPrimary)"
        },
        "helpPanel": {
            "text": "var(--colorDialogHelpPanelText)"
        }
    },
    "progress": {
        "background": {
            "primary": "var(--colorProgressBackgroundPrimary)",
            "secondary": "var(--colorProgressBackgroundSecondary)"
        },
        "text": {
            "info": "var(--colorProgressTextInfo)"
        }
    },
    "progressBar": {
        "foreground": {
            "linear": "var(--colorProgressBarForegroundLinear)",
            "circular": "var(--colorProgressBarForegroundCircular)"
        },
        "background": {
            "linear": "var(--colorProgressBarBackgroundLinear)"
        },
        "text": {
            "primary": "var(--colorProgressBarTextPrimary)"
        }
    },
    "pagination": {
        "background": {
            "default": "var(--colorPaginationBackgroundDefault)",
            "hover": "var(--colorPaginationBackgroundHover)",
            "active": "var(--colorPaginationBackgroundActive)",
            "disabled": "var(--colorPaginationBackgroundDisabled)"
        },
        "text": {
            "default": "var(--colorPaginationTextDefault)",
            "hover": "var(--colorPaginationTextHover)",
            "active": "var(--colorPaginationTextActive)",
            "disabled": "var(--colorPaginationTextDisabled)"
        },
        "icon": {
            "default": "var(--colorPaginationIconDefault)",
            "hover": "var(--colorPaginationIconHover)",
            "active": "var(--colorPaginationIconActive)",
            "disabled": "var(--colorPaginationIconDisabled)"
        }
    },
    "toggle": {
        "icon": {
            "default": "var(--colorToggleIconDefault)",
            "active": "var(--colorToggleIconActive)",
            "disabled": "var(--colorToggleIconDisabled)"
        },
        "background": {
            "on": "var(--colorToggleBackgroundOn)",
            "off": "var(--colorToggleBackgroundOff)",
            "disabled": "var(--colorToggleBackgroundDisabled)"
        },
        "foreground": {
            "on": "var(--colorToggleForegroundOn)",
            "off": "var(--colorToggleForegroundOff)",
            "disabled": "var(--colorToggleForegroundDisabled)"
        },
        "overlay": {
            "on": "var(--colorToggleOverlayOn)",
            "off": "var(--colorToggleOverlayOff)"
        }
    },
    "text": {
        "primary": "var(--colorTextPrimary)",
        "secondary": "var(--colorTextSecondary)",
        "tertiary": "var(--colorTextTertiary)",
        "disabled": "var(--colorTextDisabled)",
        "inverse": "var(--colorTextInverse)",
        "selected": "var(--colorTextSelected)",
        "info": "var(--colorTextInfo)",
        "success": "var(--colorTextSuccess)",
        "warning": "var(--colorTextWarning)",
        "danger": "var(--colorTextDanger)",
        "feature": "var(--colorTextFeature)",
        "link": {
            "default": "var(--colorTextLinkDefault)",
            "pressed": "var(--colorTextLinkPressed)"
        }
    },
    "icon": {
        "primary": "var(--colorIconPrimary)",
        "secondary": "var(--colorIconSecondary)",
        "tertiary": "var(--colorIconTertiary)",
        "disabled": "var(--colorIconDisabled)",
        "inverse": "var(--colorIconInverse)",
        "selected": "var(--colorIconSelected)",
        "info": "var(--colorIconInfo)",
        "success": "var(--colorIconSuccess)",
        "warning": "var(--colorIconWarning)",
        "attention": "var(--colorIconAttention)",
        "danger": "var(--colorIconDanger)",
        "feature": "var(--colorIconFeature)",
        "link": {
            "default": "var(--colorIconLinkDefault)",
            "pressed": "var(--colorIconLinkPressed)"
        }
    },
    "border": {
        "primary": "var(--colorBorderPrimary)",
        "secondary": "var(--colorBorderSecondary)",
        "tertiary": "var(--colorBorderTertiary)",
        "disabled": "var(--colorBorderDisabled)",
        "inverse": "var(--colorBorderInverse)",
        "selected": "var(--colorBorderSelected)",
        "info": "var(--colorBorderInfo)",
        "success": "var(--colorBorderSuccess)",
        "warning": "var(--colorBorderWarning)",
        "danger": "var(--colorBorderDanger)",
        "feature": "var(--colorBorderFeature)",
        "input": "var(--colorBorderInput)",
        "link": {
            "default": "var(--colorBorderLinkDefault)",
            "pressed": "var(--colorBorderLinkPressed)"
        }
    },
    "background": {
        "primary": {
            "default": "var(--colorBackgroundPrimaryDefault)",
            "hovered": "var(--colorBackgroundPrimaryHovered)",
            "pressed": "var(--colorBackgroundPrimaryPressed)",
            "transparent": "var(--colorBackgroundPrimaryTransparent)"
        },
        "secondary": {
            "default": "var(--colorBackgroundSecondaryDefault)",
            "hovered": "var(--colorBackgroundSecondaryHovered)",
            "pressed": "var(--colorBackgroundSecondaryPressed)"
        },
        "tertiary": "var(--colorBackgroundTertiary)",
        "disabled": "var(--colorBackgroundDisabled)",
        "contrast": "var(--colorBackgroundContrast)",
        "inverse": {
            "primary": "var(--colorBackgroundInversePrimary)",
            "secondary": "var(--colorBackgroundInverseSecondary)",
            "neutral": "var(--colorBackgroundInverseNeutral)",
            "success": "var(--colorBackgroundInverseSuccess)"
        },
        "selected": "var(--colorBackgroundSelected)",
        "info": "var(--colorBackgroundInfo)",
        "success": "var(--colorBackgroundSuccess)",
        "warning": "var(--colorBackgroundWarning)",
        "danger": "var(--colorBackgroundDanger)",
        "feature": "var(--colorBackgroundFeature)",
        "supplemantary": {
            "default": "var(--colorBackgroundSupplemantaryDefault)"
        }
    },
    "overlay": {
        "background": {
            "primary": "var(--colorOverlayBackgroundPrimary)",
            "muted": "var(--colorOverlayBackgroundMuted)"
        }
    },
    "actionButton": {
        "border": {
            "secondary": "var(--colorActionButtonBorderSecondary)",
            "disabled": "var(--colorActionButtonBorderDisabled)"
        },
        "text": {
            "primary": "var(--colorActionButtonTextPrimary)",
            "secondary": "var(--colorActionButtonTextSecondary)",
            "ternary": "var(--colorActionButtonTextTernary)",
            "disabled": "var(--colorActionButtonTextDisabled)"
        },
        "icon": {
            "primary": "var(--colorActionButtonIconPrimary)",
            "secondary": "var(--colorActionButtonIconSecondary)",
            "ternary": "var(--colorActionButtonIconTernary)",
            "disabled": "var(--colorActionButtonIconDisabled)"
        },
        "background": {
            "disabled": "var(--colorActionButtonBackgroundDisabled)",
            "primary": {
                "default": "var(--colorActionButtonBackgroundPrimaryDefault)",
                "hover": "var(--colorActionButtonBackgroundPrimaryHover)",
                "pressed": "var(--colorActionButtonBackgroundPrimaryPressed)"
            },
            "secondary": {
                "default": "var(--colorActionButtonBackgroundSecondaryDefault)",
                "hover": "var(--colorActionButtonBackgroundSecondaryHover)",
                "pressed": "var(--colorActionButtonBackgroundSecondaryPressed)"
            },
            "ternary": {
                "default": "var(--colorActionButtonBackgroundTernaryDefault)",
                "hover": "var(--colorActionButtonBackgroundTernaryHover)",
                "pressed": "var(--colorActionButtonBackgroundTernaryPressed)"
            },
            "createRelease": {
                "default": "var(--colorActionButtonBackgroundCreateReleaseDefault)",
                "hover": "var(--colorActionButtonBackgroundCreateReleaseHover)",
                "pressed": "var(--colorActionButtonBackgroundCreateReleasePressed)"
            }
        }
    },
    "card": {
        "background": {
            "default": "var(--colorCardBackgroundDefault)",
            "hover": "var(--colorCardBackgroundHover)"
        }
    },
    "carousel": {
        "text": {
            "primary": "var(--colorCarouselTextPrimary)",
            "muted": "var(--colorCarouselTextMuted)"
        },
        "icon": {
            "default": "var(--colorCarouselIconDefault)",
            "hover": "var(--colorCarouselIconHover)",
            "selected": "var(--colorCarouselIconSelected)",
            "active": "var(--colorCarouselIconActive)"
        },
        "background": {
            "primary": "var(--colorCarouselBackgroundPrimary)"
        }
    },
    "navList": {
        "text": {
            "default": "var(--colorNavListTextDefault)",
            "hover": "var(--colorNavListTextHover)",
            "active": "var(--colorNavListTextActive)"
        },
        "icon": {
            "default": "var(--colorNavListIconDefault)",
            "hover": "var(--colorNavListIconHover)",
            "active": "var(--colorNavListIconActive)"
        },
        "background": {
            "default": "var(--colorNavListBackgroundDefault)",
            "hover": "var(--colorNavListBackgroundHover)",
            "active": "var(--colorNavListBackgroundActive)"
        }
    },
    "popover": {
        "background": {
            "primary": "var(--colorPopoverBackgroundPrimary)",
            "secondary": "var(--colorPopoverBackgroundSecondary)"
        }
    },
    "codeEditor": {
        "background": "var(--colorCodeEditorBackground)",
        "foreground": "var(--colorCodeEditorForeground)",
        "selection": "var(--colorCodeEditorSelection)",
        "selectionMatch": "var(--colorCodeEditorSelectionMatch)",
        "text": {
            "default": "var(--colorCodeEditorTextDefault)",
            "highlight": "var(--colorCodeEditorTextHighlight)",
            "hintHighlight": "var(--colorCodeEditorTextHintHighlight)",
            "gutter": "var(--colorCodeEditorTextGutter)"
        },
        "code": {
            "selectedBackground": "var(--colorCodeEditorCodeSelectedBackground)",
            "linenumberColor": "var(--colorCodeEditorCodeLinenumberColor)",
            "cursorBorderLeft": "var(--colorCodeEditorCodeCursorBorderLeft)",
            "cursorBorderRight": "var(--colorCodeEditorCodeCursorBorderRight)",
            "activelineBackground": "var(--colorCodeEditorCodeActivelineBackground)",
            "fatCursorBackground": "var(--colorCodeEditorCodeFatCursorBackground)",
            "atomNumberKeywordVariableAttributeQuoteColor": "var(--colorCodeEditorCodeAtomNumberKeywordVariableAttributeQuoteColor)",
            "variableColor": "var(--colorCodeEditorCodeVariableColor)",
            "propertyColor": "var(--colorCodeEditorCodePropertyColor)",
            "punctuationUnitNegativeColor": "var(--colorCodeEditorCodePunctuationUnitNegativeColor)",
            "stringOperatorColor": "var(--colorCodeEditorCodeStringOperatorColor)",
            "positiveColor": "var(--colorCodeEditorCodePositiveColor)",
            "variable2Variable3String2UrlColor": "var(--colorCodeEditorCodeVariable2Variable3String2UrlColor)",
            "defTagBuiltinQualifierHeaderEmColor": "var(--colorCodeEditorCodeDefTagBuiltinQualifierHeaderEmColor)",
            "bracketCommentColor": "var(--colorCodeEditorCodeBracketCommentColor)",
            "errorInvalidcharColor": "var(--colorCodeEditorCodeErrorInvalidcharColor)",
            "matchingbracketColor": "var(--colorCodeEditorCodeMatchingbracketColor)",
            "hintsBackground": "var(--colorCodeEditorCodeHintsBackground)",
            "hint": "var(--colorCodeEditorCodeHint)",
            "hintActiveBackground": "var(--colorCodeEditorCodeHintActiveBackground)",
            "syntax": {
                "tagName": "var(--colorCodeEditorCodeSyntaxTagName)",
                "comment": "var(--colorCodeEditorCodeSyntaxComment)",
                "bracket": "var(--colorCodeEditorCodeSyntaxBracket)",
                "className": "var(--colorCodeEditorCodeSyntaxClassName)",
                "propertyName": "var(--colorCodeEditorCodeSyntaxPropertyName)",
                "variableName": "var(--colorCodeEditorCodeSyntaxVariableName)",
                "attributeName": "var(--colorCodeEditorCodeSyntaxAttributeName)",
                "number": "var(--colorCodeEditorCodeSyntaxNumber)",
                "operator": "var(--colorCodeEditorCodeSyntaxOperator)",
                "keyword": "var(--colorCodeEditorCodeSyntaxKeyword)",
                "typeName": "var(--colorCodeEditorCodeSyntaxTypeName)",
                "typeOperator": "var(--colorCodeEditorCodeSyntaxTypeOperator)",
                "string": "var(--colorCodeEditorCodeSyntaxString)",
                "meta": "var(--colorCodeEditorCodeSyntaxMeta)",
                "regexp": "var(--colorCodeEditorCodeSyntaxRegexp)",
                "name": "var(--colorCodeEditorCodeSyntaxName)",
                "quote": "var(--colorCodeEditorCodeSyntaxQuote)",
                "heading": "var(--colorCodeEditorCodeSyntaxHeading)",
                "strong": "var(--colorCodeEditorCodeSyntaxStrong)",
                "emphasis": "var(--colorCodeEditorCodeSyntaxEmphasis)",
                "deleted": "var(--colorCodeEditorCodeSyntaxDeleted)",
                "deletedBackground": "var(--colorCodeEditorCodeSyntaxDeletedBackground)",
                "atom": "var(--colorCodeEditorCodeSyntaxAtom)",
                "bool": "var(--colorCodeEditorCodeSyntaxBool)",
                "specialVariableName": "var(--colorCodeEditorCodeSyntaxSpecialVariableName)",
                "url": "var(--colorCodeEditorCodeSyntaxUrl)",
                "escape": "var(--colorCodeEditorCodeSyntaxEscape)",
                "link": "var(--colorCodeEditorCodeSyntaxLink)",
                "invalid": "var(--colorCodeEditorCodeSyntaxInvalid)"
            },
            "indentationMarker": {
                "color": "var(--colorCodeEditorCodeIndentationMarkerColor)",
                "activeColor": "var(--colorCodeEditorCodeIndentationMarkerActiveColor)"
            }
        },
        "toolbar": {
            "background": "var(--colorCodeEditorToolbarBackground)",
            "button": {
                "hover": "var(--colorCodeEditorToolbarButtonHover)"
            }
        }
    },
    "scrollbar": {
        "background": "var(--colorScrollbarBackground)",
        "handle": "var(--colorScrollbarHandle)"
    },
    "categorySelectList": {
        "text": {
            "default": "var(--colorCategorySelectListTextDefault)",
            "hover": "var(--colorCategorySelectListTextHover)",
            "pressed": "var(--colorCategorySelectListTextPressed)"
        },
        "icon": {
            "default": "var(--colorCategorySelectListIconDefault)",
            "hover": "var(--colorCategorySelectListIconHover)",
            "pressed": "var(--colorCategorySelectListIconPressed)"
        },
        "background": {
            "default": "var(--colorCategorySelectListBackgroundDefault)",
            "hover": "var(--colorCategorySelectListBackgroundHover)",
            "pressed": "var(--colorCategorySelectListBackgroundPressed)"
        }
    },
    "spaceSwitcher": {
        "background": {
            "default": "var(--colorSpaceSwitcherBackgroundDefault)",
            "hover": "var(--colorSpaceSwitcherBackgroundHover)",
            "pressed": "var(--colorSpaceSwitcherBackgroundPressed)"
        },
        "text": {
            "default": "var(--colorSpaceSwitcherTextDefault)",
            "hover": "var(--colorSpaceSwitcherTextHover)",
            "pressed": "var(--colorSpaceSwitcherTextPressed)"
        },
        "icon": {
            "default": "var(--colorSpaceSwitcherIconDefault)",
            "hover": "var(--colorSpaceSwitcherIconHover)",
            "pressed": "var(--colorSpaceSwitcherIconPressed)"
        },
        "border": {
            "primary": "var(--colorSpaceSwitcherBorderPrimary)"
        }
    },
    "navTab": {
        "background": {
            "default": "var(--colorNavTabBackgroundDefault)",
            "hover": "var(--colorNavTabBackgroundHover)",
            "active": "var(--colorNavTabBackgroundActive)"
        },
        "text": {
            "default": "var(--colorNavTabTextDefault)",
            "hover": "var(--colorNavTabTextHover)",
            "active": "var(--colorNavTabTextActive)"
        },
        "icon": {
            "default": "var(--colorNavTabIconDefault)",
            "hover": "var(--colorNavTabIconHover)",
            "active": "var(--colorNavTabIconActive)"
        },
        "border": {
            "primary": "var(--colorNavTabBorderPrimary)"
        }
    },
    "navButton": {
        "icon": {
            "default": "var(--colorNavButtonIconDefault)",
            "hover": "var(--colorNavButtonIconHover)",
            "pressed": "var(--colorNavButtonIconPressed)",
            "active": "var(--colorNavButtonIconActive)"
        },
        "background": {
            "default": "var(--colorNavButtonBackgroundDefault)",
            "hover": "var(--colorNavButtonBackgroundHover)",
            "pressed": "var(--colorNavButtonBackgroundPressed)",
            "active": "var(--colorNavButtonBackgroundActive)"
        }
    }
} as const;
export const shadow = {
    "extraSmall": "var(--shadowExtraSmall)",
    "small": "var(--shadowSmall)",
    "medium": "var(--shadowMedium)",
    "large": "var(--shadowLarge)",
    "layoutPrimary": "var(--shadowLayoutPrimary)",
    "drawer": "var(--shadowDrawer)",
    "focused": "var(--shadowFocused)",
    "focusedInset": "var(--shadowFocusedInset)"
} as const;
