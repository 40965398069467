import * as globals from "./globals";
import type { OctopusTextTheme } from "./OctopusTextTheme";
export const textV2Theme: OctopusTextTheme = {
    "textHeadingXSmall": "500 0.75rem/1.25rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "textHeadingSmall": "500 1rem/1.5rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "textHeadingMedium": "500 1.25rem/1.75rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "textHeadingLarge": "500 1.5rem/2rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "textHeadingXLarge": "500 2rem/2.75rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "textRegularDefaultXSmall": "400 0.75rem/1.25rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "textRegularDefaultSmall": "400 0.813rem/1.25rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "textRegularDefaultMedium": "400 0.875rem/1.25rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "textRegularDefaultLarge": "400 1rem/1.5rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "textRegularBoldXSmall": "500 0.75rem/1.25rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "textRegularBoldSmall": "500 0.813rem/1.25rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "textRegularBoldMedium": "500 0.875rem/1.25rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "textRegularBoldLarge": "500 1rem/1.5rem var(--fontFamilyDefault, 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol')",
    "fontFamilyDefault": "BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Oxygen Sans\", Ubuntu, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
    "fontFamilyCode": "ui-monospace, \"SF Mono\", Menlo, Consolas, \"Liberation Mono\", \"Oxygen Mono\", \"Ubuntu Mono\", monospace"
};
