import { css, cx } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React, { type AriaAttributes, forwardRef } from "react";
import { resetStyles } from "../../utils/resetStyles";
import { sideNavBarTabStyles } from "./sideNavBarTabStyles";
export type SideNavBarButtonTabAriaAttributes = Pick<AriaAttributes, "aria-controls" | "aria-haspopup" | "aria-expanded">;
export interface SideNavBarIconButtonTabProps extends SideNavBarButtonTabAriaAttributes {
    onClick?: React.MouseEventHandler;
    accessibleName: string;
    icon: React.ReactNode;
}
export const SideNavBarIconButtonTab = forwardRef<HTMLButtonElement, SideNavBarIconButtonTabProps>(({ onClick, accessibleName, icon, ...otherProps }, ref) => {
    const ariaAttributes = getSideNavBarButtonTabAriaAttributes(otherProps);
    return (<button className={sideNavBarButtonTabStyles} onClick={onClick} aria-label={accessibleName} ref={ref} {...ariaAttributes}>
            {icon}
        </button>);
});
function getSideNavBarButtonTabAriaAttributes<T extends SideNavBarButtonTabAriaAttributes>(props: T): SideNavBarButtonTabAriaAttributes {
    const ariaAttributes: SideNavBarButtonTabAriaAttributes = {
        ["aria-controls"]: props["aria-controls"],
        ["aria-haspopup"]: props["aria-haspopup"],
        ["aria-expanded"]: props["aria-expanded"],
    };
    return ariaAttributes;
}
const sideNavBarButtonTabStyles = cx(css(resetStyles.button, {
    "&[aria-expanded=true]": {
        background: themeTokens.color.navTab.background.active,
    },
}), sideNavBarTabStyles);
SideNavBarIconButtonTab.displayName = "SideNavBarIconButtonTab";
