import { css, cx } from "@emotion/css";
import { borderRadius, colorScales, fontWeight, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
const badgeStyles = css({
    display: "inline-flex",
    borderRadius: borderRadius.circle,
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
});
const variantStyles: {
    [key in BadgeVariant]: string;
} = {
    danger: css({
        backgroundColor: colorScales.red["500"],
        color: colorScales.white,
    }),
    default: css({
        backgroundColor: themeTokens.color.background.tertiary,
        color: themeTokens.color.text.primary,
    }),
    success: css({
        backgroundColor: colorScales.green["500"],
        color: colorScales.white,
    }),
    info: css({
        backgroundColor: colorScales.blue["500"],
        color: colorScales.white,
    }),
    warning: css({
        backgroundColor: colorScales.yellow["400"],
        color: colorScales.white,
    }),
    neutral: css({
        backgroundColor: themeTokens.color.background.inverse.neutral,
        color: themeTokens.color.text.inverse,
    }),
};
type BadgeSize = BadgeProps["size"];
const sizeStyles: {
    [key in BadgeSize]: string;
} = {
    xSmall: css({
        height: "16px",
        minWidth: "16px",
        font: text.regular.default.xSmall,
        fontSize: "12px",
        padding: `0 ${space["4"]}`,
    }),
    small: css({
        height: "20px",
        minWidth: "20px",
        font: text.regular.default.xSmall,
        padding: `0 ${space["6"]}`,
        gap: space["6"],
    }),
    medium: css({
        height: "24px",
        minWidth: "24px",
        font: text.regular.default.small,
        padding: `0 ${space["8"]} 0 ${space["6"]}`,
        gap: space["8"],
    }),
};
type BadgeVariant = "default" | "info" | "success" | "warning" | "danger" | "neutral";
interface SharedBadgeProps {
    variant: BadgeVariant;
}
interface SmallOrMediumBadgeProps extends SharedBadgeProps {
    size: "small" | "medium";
    count?: number;
    icon?: React.ReactNode;
    label?: string;
}
export interface ExtraSmallBadgeProps extends SharedBadgeProps {
    size: "xSmall";
    count?: number;
    label?: string;
}
export type BadgeProps = SmallOrMediumBadgeProps | ExtraSmallBadgeProps;
export function Badge(props: BadgeProps) {
    return props.size === "xSmall" ? (<ExtraSmallBadge size={props.size} count={props.count} label={props.label} variant={props.variant}/>) : (<SmallOrMediumBadge size={props.size} variant={props.variant} count={props.count} label={props.label} icon={props.icon}/>);
}
function ExtraSmallBadge(props: ExtraSmallBadgeProps) {
    return (<div className={cx(badgeStyles, variantStyles[props.variant], sizeStyles[props.size])}>
            <BadgeLabel label={props.label} count={props.count} size={props.size}/>
        </div>);
}
function SmallOrMediumBadge(props: SmallOrMediumBadgeProps) {
    return (<div className={cx(badgeStyles, variantStyles[props.variant], sizeStyles[props.size])}>
            {props.icon && <BadgeIcon size={props.size} icon={props.icon}/>}
            <BadgeLabel label={props.label} count={props.count} size={props.size}/>
        </div>);
}
const smallBadgeIconStyles = css({
    width: "16px",
    height: "16px",
});
const mediumBadgeIconStyles = css({
    width: "20px",
    height: "20px",
});
interface BadgeIconProps {
    icon: React.ReactNode;
    size: SmallOrMediumBadgeProps["size"];
}
function BadgeIcon({ icon, size }: BadgeIconProps) {
    return <div className={size === "medium" ? mediumBadgeIconStyles : smallBadgeIconStyles}>{icon}</div>;
}
interface BadgeLabelProps {
    label?: string;
    count?: number;
    size: "xSmall" | "small" | "medium";
}
const badgeLabelStyles = css({
    display: "flex",
    gap: space["4"],
    justifyContent: "center",
    alignItems: "center",
});
const xSmallLabelStyles = css({
    fontWeight: fontWeight[500],
    fontSize: "12px",
    lineHeight: "16px",
});
function BadgeLabel({ label, count, size }: BadgeLabelProps) {
    return (<span className={cx(badgeLabelStyles, { [xSmallLabelStyles]: size === "xSmall" })}>
            {count && <BadgeCount count={count} size={size}/>}
            {label}
        </span>);
}
const badgeCountStyles = css({
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
});
const badgeCountSizeStyles: {
    [key in BadgeSize]: string;
} = {
    xSmall: css({
        fontWeight: fontWeight[500],
        fontSize: "12px",
        lineHeight: "16px",
    }),
    small: css({
        font: text.regular.bold.xSmall,
    }),
    medium: css({
        font: text.regular.bold.small,
    }),
};
function BadgeCount({ count, size }: {
    count: number;
    size: BadgeSize;
}) {
    return <span className={cx(badgeCountStyles, badgeCountSizeStyles[size])}>{count}</span>;
}
