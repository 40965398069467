import DateUtils from "@date-io/date-fns";
import { css } from "@emotion/css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { DatePicker as MaterialDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { themeTokens, text } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
export interface DatePickerProps {
    /**
     * A boolean property which controls whether the date picker is disabled.
     */
    disabled?: boolean;
    /**
     * The label to use for the date picker.
     */
    label?: string;
    /**
     * An optional error string to display below the date picker.
     */
    error?: string;
    /**
     * An optional name to use for the date picker.
     */
    name?: string;
    /**
     * A boolean flag to indicate whether the date should be auto accepted on selection.
     * Please note that `autoOk` does not apply when the variant is `dialog`
     */
    autoOk?: boolean;
    /**
     * The minimum date available as a selection in the date picker. Any dates before this
     * will not be selectable.
     */
    minDate?: Date;
    /**
     * The date format to use when displaying the date.
     * @default "MMM dd, yyyy"
     * @remarks please see https://date-fns.org/v2.30.0/docs/format for available formatting options.
     */
    format?: string;
    /**
     * An optional placeholder to use for the date picker. If no value is provided, then the label
     * value is used if specified.
     */
    placeholder?: string;
    /**
     * The variant to use for the date picker i.e. whether it should show inline or as a dialog.
     */
    variant: "inline" | "dialog";
    /**
     * The current date value to use for the picker.
     */
    value: Date | null;
    /**
     * The change callback handler
     * @description
     * The onChange callback is fired whenever the date is changed and is guaranteed to be a valid
     * date.
     */
    onChange: (newValue: Date) => void;
    /**
     * The clear callback handler
     * @description
     * The onClear callback is fired whenever the value is cleared/date is set to null
     */
    onClear?: () => void;
}
export function DatePicker({ value, label, error, placeholder = label, name, disabled, format, autoOk, minDate, variant, onChange, onClear }: DatePickerProps) {
    const latestChangeHandler = React.useRef(onChange);
    latestChangeHandler.current = onChange;
    const latestClearHandler = React.useRef(onClear);
    latestClearHandler.current = onClear;
    const handleChange = React.useCallback((value: Date | null) => {
        if (value) {
            latestChangeHandler.current?.(value);
        }
        else {
            latestClearHandler.current?.();
        }
    }, []);
    return (<ThemeProvider theme={pickerMaterialTheme}>
            <MuiPickersUtilsProvider utils={DateUtils}>
                {/* We need some sort of parent underneath the PickersUtils provider, otherwise we get an error being logged
        when the date picker is disabled.*/}
                <>
                    <MaterialDatePicker value={value} onChange={handleChange} clearable={onClear !== undefined ? true : undefined} disabled={disabled} autoOk={autoOk} minDate={minDate} error={Boolean(error)} label={label} name={name} orientation="landscape" fullWidth={true} format={format ?? "MMM dd, yyyy"} placeholder={placeholder ?? label} variant={variant}/>
                    {error && <p className={errorLabelStyles}>{error}</p>}
                </>
            </MuiPickersUtilsProvider>
        </ThemeProvider>);
}
const errorLabelStyles = css({
    color: themeTokens.color.textField.text.danger,
    font: text.regular.default.xSmall,
    margin: 0,
});
//This is the only real customization that the legacy material/pickers provides us
//and we don't want to duplicate the theme based on whether it's light or dark as our
//theme tokens already swaps these for us. We therefore apply the appropriate overrides
//to ensure the date picker styles are isolated from both a consumer's MUI theme as well
// as the default MUI theme.
const pickerMaterialTheme = createMuiTheme({
    overrides: {
        MuiTypography: {
            colorPrimary: {
                color: themeTokens.color.text.primary,
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: themeTokens.color.background.secondary.default,
            },
        },
        MuiFormLabel: {
            root: {
                color: themeTokens.color.textField.text.secondary,
                "&$focused": {
                    color: themeTokens.color.textField.text.primary,
                },
            },
        },
        MuiPickersCalendarHeader: {
            root: {
                color: themeTokens.color.text.primary,
            },
            iconButton: {
                backgroundColor: themeTokens.color.button.background.quiet.default,
                color: themeTokens.color.button.text.quiet,
                "&:hover": {
                    backgroundColor: themeTokens.color.button.background.quiet.hover,
                },
            },
            switchHeader: {
                color: themeTokens.color.text.primary,
            },
            dayLabel: {
                color: themeTokens.color.text.primary,
            },
        },
        MuiPickersDay: {
            day: {
                color: themeTokens.color.text.primary,
                "&:hover": {
                    backgroundColor: themeTokens.color.button.background.quiet.hover,
                    color: themeTokens.color.button.text.quiet,
                },
            },
            daySelected: {
                color: themeTokens.color.text.inverse,
                backgroundColor: themeTokens.color.background.selected,
                "&:hover": {
                    backgroundColor: themeTokens.color.button.background.quiet.hover,
                    color: themeTokens.color.button.text.quiet,
                },
            },
            dayDisabled: {
                color: themeTokens.color.text.disabled,
            },
            current: {
                color: themeTokens.color.text.primary,
                border: `solid 1px ${themeTokens.color.border.primary}`,
            },
        },
        MuiPickersYear: {
            root: {
                color: themeTokens.color.text.primary,
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: themeTokens.color.button.background.primary.default,
            },
        },
        MuiInputBase: {
            root: {
                color: themeTokens.color.textField.text.primary,
            },
        },
        MuiButton: {
            root: {
                color: themeTokens.color.button.text.primary,
                "&$disabled": {
                    color: themeTokens.color.button.text.disabled,
                },
            },
            textPrimary: {
                color: themeTokens.color.button.text.quiet,
                backgroundColor: themeTokens.color.button.background.quiet.default,
                "&:hover": {
                    backgroundColor: themeTokens.color.button.background.quiet.hover,
                    color: themeTokens.color.button.text.quiet,
                },
            },
        },
        MuiInput: {
            underline: {
                "&::before": {
                    borderColor: themeTokens.color.textField.border.default,
                },
                "&:hover:not($disabled)::before": {
                    borderColor: themeTokens.color.textField.border.hover,
                },
                "&::after": {
                    borderColor: themeTokens.color.textField.border.active,
                },
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: themeTokens.color.background.secondary.default,
            },
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: themeTokens.color.text.secondary,
            },
            toolbarBtnSelected: { color: themeTokens.color.text.primary },
        },
        MuiIconButton: {
            root: {
                "&$disabled": { color: themeTokens.color.button.text.disabled },
            },
        },
    },
});
