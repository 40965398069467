import { css } from "@emotion/css";
import { borderRadius, borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ChangeEvent } from "react";
import React from "react";
import { resetStyles } from "../../utils";
import { MagnifyingGlassIcon } from "../Icon/MagnifyingGlassIcon";
import { ClearInputButton } from "./ClearInputButton";
export interface SearchInputProps {
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    accessibleName: string;
    autoFocus?: boolean;
}
export function SearchInput({ value, placeholder, onChange, autoFocus, accessibleName }: SearchInputProps) {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };
    const handleClear = () => {
        onChange("");
        inputRef.current?.focus();
    };
    return (<div className={inputContainerStyles}>
            <span className={searchIconContainerStyles}>
                <MagnifyingGlassIcon />
            </span>

            <input ref={inputRef} className={inputStyles} placeholder={placeholder} value={value} onChange={handleChange} autoFocus={autoFocus} aria-label={accessibleName}/>

            {value && (<span className={clearInputButtonContainerStyles}>
                    <ClearInputButton onClear={handleClear} accessibleName={`Clear ${accessibleName}`}/>
                </span>)}
        </div>);
}
const inputHorizontalPadding = space[12];
const inputContainerStyles = css({
    position: "relative",
    display: "flex",
    font: text.regular.default.medium,
    height: "2.25rem",
    width: "100%",
    boxSizing: "border-box",
    background: "transparent",
    outline: "transparent 2px solid",
    outlineOffset: `-${borderWidth["1"]}`,
    border: `solid ${borderWidth["1"]} ${themeTokens.color.textField.border.default}`,
    borderRadius: borderRadius.small,
    color: themeTokens.color.textField.text.primary,
    ":hover": {
        borderColor: themeTokens.color.textField.border.hover,
    },
    ":focus-visible, :focus-within": {
        outlineColor: themeTokens.color.textField.border.active,
        outlineWidth: borderWidth[2],
    },
});
const inputStyles = css({
    ...resetStyles.input,
    flex: 1,
    width: "100%",
    // Padding needs to account for icons on the side e.g. the search icon
    paddingLeft: `calc(${inputHorizontalPadding} * 2 + 1em)`,
    paddingRight: `calc(${inputHorizontalPadding} * 2 + 1em)`,
    "&::placeholder": {
        color: themeTokens.color.textField.text.secondary,
        opacity: 1,
    },
});
const searchIconContainerStyles = css({
    display: "flex",
    alignItems: "center",
    pointerEvents: "none",
    position: "absolute",
    transitionProperty: "color",
    transitionDuration: "0.2s",
    top: 0,
    bottom: 0,
    left: inputHorizontalPadding,
    color: themeTokens.color.textField.icon.primary,
});
const clearInputButtonContainerStyles = css({
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: inputHorizontalPadding,
});
