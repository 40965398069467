import React from "react";
import { getButtonStyles, labelStyles } from "./buttonStyles";
export interface ButtonProps {
    label: string;
    onClick: (event: React.MouseEvent) => void;
    importance: ButtonImportance;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    disabled?: boolean;
    size?: ButtonSize;
}
export function Button({ label, onClick, importance, size = "medium", leftIcon, rightIcon, disabled = false }: ButtonProps) {
    const buttonStyles = getButtonStyles(importance, disabled, size);
    return (<button className={buttonStyles} onClick={onClick} disabled={disabled}>
            {leftIcon && <span>{leftIcon}</span>}
            <span className={labelStyles}>{label}</span>
            {rightIcon && <span>{rightIcon}</span>}
        </button>);
}
export const buttonImportanceLevels = ["primary", "secondary", "tertiary", "destructive", "quiet", "loud", "neutral"] as const;
export type ButtonImportance = (typeof buttonImportanceLevels)[number];
export const buttonSizes = ["medium", "small"] as const;
export type ButtonSize = (typeof buttonSizes)[number];
