import { darkTheme, lightTheme, textTheme, textV2Theme } from "@octopusdeploy/design-system-tokens";
import type { TextThemeName, ThemeName } from "../Theme";
export function getTheme(themeName: ThemeName) {
    switch (themeName) {
        case "light":
            return lightTheme;
        case "dark":
            return darkTheme;
        default:
            return lightTheme;
    }
}
export function getTextTheme(themeName: TextThemeName) {
    switch (themeName) {
        case "text":
            return textTheme;
        case "textV2":
            return textV2Theme;
        default:
            return textTheme;
    }
}
