import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
const maxWideDrawerWidth = "975px";
const maxNarrowDrawerWidth = "730px";
export const drawerContainerStyles = css({
    // Using `&` twice to increase specificity
    "&&": {
        backgroundColor: themeTokens.color.background.primary.default,
        width: "90%",
        overflowY: "visible",
        color: themeTokens.color.text.primary,
    },
});
export const wideContainerStyles = css({
    maxWidth: maxWideDrawerWidth,
});
export const narrowContainerStyles = css({
    maxWidth: maxNarrowDrawerWidth,
});
export const drawerHeaderStyles = css({
    padding: space["16"],
    display: "grid",
    gridTemplateColumns: "1FR auto",
});
export const drawerTitleStyles = css({
    font: text.heading.large,
    margin: 0,
    gridRow: 1,
    gridColumn: 1,
});
export const drawerSubtitleTitleStyles = css({
    color: themeTokens.color.text.secondary,
    font: text.regular.default.large,
    gridRow: 2,
    gridColumn: 1,
    margin: 0,
});
export const drawerContentStyles = css({
    overflow: "auto",
});
export const drawerActionListStyles = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    gap: space["16"],
    gridRow: 1,
    gridColumn: 2,
});
