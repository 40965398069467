import * as globals from "./globals";
import type { OctopusTextTheme } from "./OctopusTextTheme";
export const textTheme: OctopusTextTheme = {
    "textHeadingXSmall": "500 0.75rem/1.25rem var(--fontFamilyDefault, 'Roboto')",
    "textHeadingSmall": "500 1rem/1.5rem var(--fontFamilyDefault, 'Roboto')",
    "textHeadingMedium": "500 1.25rem/1.75rem var(--fontFamilyDefault, 'Roboto')",
    "textHeadingLarge": "500 1.5rem/2rem var(--fontFamilyDefault, 'Roboto')",
    "textHeadingXLarge": "500 2rem/2.75rem var(--fontFamilyDefault, 'Roboto')",
    "textRegularDefaultXSmall": "400 0.75rem/1.25rem var(--fontFamilyDefault, 'Roboto')",
    "textRegularDefaultSmall": "400 0.813rem/1.25rem var(--fontFamilyDefault, 'Roboto')",
    "textRegularDefaultMedium": "400 0.875rem/1.5rem var(--fontFamilyDefault, 'Roboto')",
    "textRegularDefaultLarge": "400 1rem/1.5rem var(--fontFamilyDefault, 'Roboto')",
    "textRegularBoldXSmall": "500 0.75rem/1.25rem var(--fontFamilyDefault, 'Roboto')",
    "textRegularBoldSmall": "500 0.813rem/1.25rem var(--fontFamilyDefault, 'Roboto')",
    "textRegularBoldMedium": "500 0.875rem/1.5rem var(--fontFamilyDefault, 'Roboto')",
    "textRegularBoldLarge": "500 1rem/1.5rem var(--fontFamilyDefault, 'Roboto')",
    "fontFamilyDefault": "Roboto",
    "fontFamilyCode": "Consolas, monospace"
};
