import type { OctopusTextTheme, OctopusTheme } from "@octopusdeploy/design-system-tokens";
import { fontFamilyGlobal } from "@octopusdeploy/design-system-tokens";
import { useLayoutEffect } from "react";
import type { TextThemeName, ThemeName } from "./Theme";
import { getTextTheme, getTheme } from "./utils/getTheme";
export function useApplyGlobalThemeEffect(themeName: ThemeName, textThemeName: TextThemeName, customFontOverride: string | undefined, updateFontFamilyOnBody: boolean) {
    useLayoutEffect(() => {
        const theme: OctopusTheme = getTheme(themeName);
        const textTheme: OctopusTextTheme = getTextTheme(textThemeName);
        // This can be simplified once the feature toggle for switching between fonts is removed
        const fallbackFont = textThemeName === "text" ? fontFamilyGlobal["roboto"] : fontFamilyGlobal["system-ui"];
        const updatedTextTheme: OctopusTextTheme = {
            ...textTheme,
            ...(customFontOverride && { fontFamilyDefault: customFontOverride }),
        };
        // We want to manage the body separately so we can see where the cascade is being used
        const fontFamilyOnBody = updateFontFamilyOnBody && customFontOverride ? updatedTextTheme.fontFamilyDefault : fallbackFont;
        document.documentElement.style.setProperty("--fontFamilyBody", fontFamilyOnBody);
        const themeCssVariableProps = Object.entries(theme)
            .concat(Object.entries(updatedTextTheme))
            .map(([cssVariableName, value]) => `--${cssVariableName}: ${value};`);
        const serializedCss = `:root {
${themeCssVariableProps.join("\n")}
}`;
        // We manually inject the styles ourselves rather than using injectGlobals from emotion
        // injectGlobals does not have a way to remove or replace previously injected styles, so changing themes causes a new global style to be added
        let styleElement = document.querySelector(`#${globalStyleNodeId}`);
        if (styleElement) {
            styleElement.innerHTML = serializedCss;
        }
        else {
            styleElement = document.createElement("style");
            styleElement.setAttribute("id", globalStyleNodeId);
            styleElement.innerHTML = serializedCss;
            document.head.appendChild(styleElement);
        }
    }, [themeName, textThemeName, customFontOverride, updateFontFamilyOnBody]);
}
const globalStyleNodeId = "octopus-global-theme";
