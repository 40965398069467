import { css } from "@emotion/css";
import { text, themeTokens } from "@octopusdeploy/design-system-tokens";
export const sideNavBarTabStyles = css({
    font: text.regular.default.xSmall,
    color: themeTokens.color.text.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    height: "4rem",
    background: "transparent",
    ":focus-visible": {
        boxShadow: themeTokens.shadow.focusedInset,
        outline: "none",
        zIndex: 1,
    },
    ":hover": {
        backgroundColor: themeTokens.color.background.secondary.hovered,
    },
});
