import { css } from "@emotion/css";
import { space, text, themeTokens, borderRadius } from "@octopusdeploy/design-system-tokens";
import type { ReactNode } from "react";
import React from "react";
import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { Stepper } from "../Stepper";
import type { PopoverProps } from "./Popover";
import { Popover } from "./Popover";
import { PopoverContentContainer } from "./PopoverContentContainer";
import type { PopoverWidth } from "./PopoverContentContainer";
export interface PopoverSequentialProps extends PopoverProps {
    title: string;
    image?: string;
    video?: string;
    description: ReactNode;
    infoCallout?: ReactNode;
    currentStep: number;
    totalSteps: number;
    onBack: () => void;
    onNext: () => void;
    onFinish: () => void;
    width?: PopoverWidth;
}
export function PopoverSequential({ width = "wide", ...props }: PopoverSequentialProps): JSX.Element {
    return (<Popover placement={props.placement} open={props.open} anchorEl={props.anchorEl} onClose={props.onClose}>
            <PopoverContentContainer width={width}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>{props.title}</div>
                    <div>
                        <IconButton accessibleName="ClosePopover" icon={"Cancel"} onClick={props.onClose}/>
                    </div>
                </div>
                {props.image && <img className={styles.image} src={props.image}/>}
                {props.video && (<p>
                        <video src={props.video} playsInline={true} autoPlay={true} loop={true} muted={true} className={styles.video}/>
                    </p>)}
                <div className={styles.content}>{props.description}</div>
                {props.infoCallout && <div className={styles.tipContainer}>{props.infoCallout}</div>}
                <div className={styles.bottomContainer}>
                    <Stepper variant="text" currentStep={props.currentStep} totalSteps={props.totalSteps}/>
                    <div className={styles.buttonsContainer}>
                        {props.currentStep > 1 && <Button label={"Back"} importance={"secondary"} onClick={props.onBack}></Button>}
                        {props.currentStep < props.totalSteps && <Button label={"Next"} importance={"primary"} onClick={props.onNext}></Button>}
                        {props.currentStep >= props.totalSteps && <Button label={"Finish"} importance={"primary"} onClick={props.onFinish}></Button>}
                    </div>
                </div>
            </PopoverContentContainer>
        </Popover>);
}
const styles = {
    container: css({
        maxWidth: "400px",
        padding: space[16],
    }),
    titleContainer: css({
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
    }),
    title: css({
        font: text.regular.bold.large,
        paddingRight: space[8],
    }),
    content: css({
        marginTop: space[12],
        marginBottom: space[12],
        font: text.regular.default.medium,
    }),
    image: css({
        width: "100%",
        paddingTop: space[12],
    }),
    tipContainer: css({
        borderRadius: "4px",
        padding: space[12],
        marginBottom: space[12],
        font: text.regular.default.medium,
        backgroundColor: themeTokens.color.popover.background.secondary,
    }),
    bottomContainer: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }),
    buttonsContainer: css({
        display: "flex",
        flexDirection: "row",
        gap: space[8],
        justifyContent: "center",
    }),
    video: css({
        width: "100%",
        borderRadius: borderRadius.small,
        paddingTop: space[12],
    }),
};
