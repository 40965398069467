import { css, cx } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ReactElement } from "react";
import React from "react";
import { useIsLargerThanIpadResolution } from "../../hooks";
import type { SimpleMenuItem } from "../MenuItems";
import { PageActions } from "../PageActions";
import type { PageAction, PrimaryPageAction } from "../PageActions";
interface PageHeaderSecondaryMainWithActionsProps {
    /**
     * An optional primary action to display on the right side of the header
     */
    primaryAction?: PrimaryPageAction;
    /**
     * An optional list of actions to display on the right side of the header
     */
    pageActions?: PageAction[];
    /**
     * The items to show as part of the overflow menu on the right side of the header
     */
    overflowActions?: SimpleMenuItem[];
}
export type PageHeaderSecondaryMainContentProps = PageHeaderSecondaryMainWithActionsProps & PageHeaderTitleProps;
export function PageHeaderSecondaryMainContent({ titleStatusIcon, title, titleChip, titleAccessory, titleComplementaryText, ...actionProps }: PageHeaderSecondaryMainContentProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    return (<div className={cx(mainContentStyles, { [mainContentMobileStyles]: !isLargerThanIpad })}>
            <PageHeaderTitle title={title} titleStatusIcon={titleStatusIcon} titleChip={titleChip} titleAccessory={titleAccessory} titleComplementaryText={titleComplementaryText}/>
            <PageActions primaryAction={actionProps.primaryAction} actions={actionProps.pageActions} overflowActions={actionProps.overflowActions}/>
        </div>);
}
interface PageHeaderTitleProps {
    /** The title of the HeaderSecondary. */
    title: string | undefined;
    /**
     * Additional text to show immediately after the `title` with less emphasis.
     */
    titleComplementaryText?: string;
    /**
     * The status icon to show on the left side of the title.
     *
     * The status icon is only visible when this property is defined.
     */
    titleStatusIcon?: ReactElement;
    /**
     * The chip to show on the right side of the title.
     *
     * The status icon is only visible when this property is defined.
     */
    titleChip?: ReactElement;
    /**
     * An element which can be used to display in addition to the title. This is
     * displayed to the right of the title, but before any chips.
     */
    titleAccessory?: ReactElement;
}
function PageHeaderTitle({ titleStatusIcon, title, titleAccessory, titleChip, titleComplementaryText }: PageHeaderTitleProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    return (<div className={cx(titleSectionStyles, { [titleSectionMobileStyles]: !isLargerThanIpad })}>
            <div className={titleLabelStyles}>
                {titleStatusIcon}
                <span>
                    <h1 className={titleStyles}>{title}</h1>
                    {titleComplementaryText && <span className={titleComplementaryTextStyles}> {titleComplementaryText}</span>}
                </span>
            </div>
            {titleAccessory}
            <div className={chipAndLoadingIndicatorWrapperStyles}>{titleChip}</div>
        </div>);
}
const mainContentStyles = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: space["48"],
    minHeight: "2.25rem",
});
const mainContentMobileStyles = css({
    flexDirection: "column",
    gap: space["24"],
    alignItems: "flex-start",
});
const titleLabelStyles = css({
    display: "flex",
    gap: space["12"],
    alignItems: "center",
});
const titleSectionStyles = css({
    display: "flex",
    gap: space["8"],
    alignItems: "center",
});
const titleSectionMobileStyles = css({
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
});
const titleStyles = css({
    all: "unset",
    font: text.heading.medium,
    color: themeTokens.color.text.primary,
});
const titleComplementaryTextStyles = css({
    font: text.heading.medium,
    color: themeTokens.color.text.tertiary,
});
const chipAndLoadingIndicatorWrapperStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space["12"],
});
