import { css } from "@emotion/css";
import { text, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { MouseEventHandler } from "react";
import React from "react";
import type { DesignSystemLinkHref, ShowLinkAsActive } from "../../routing";
import { resetStyles } from "../../utils/resetStyles";
import { Divider } from "../Divider";
import { AreaNavListLink } from "./AreaNavListLink";
export interface AreaNavListProps {
    listItems: ReadonlyArray<AreaNavListItem>;
    onLinkClicked?: () => void;
}
export interface AreaNavListGroup {
    heading: string | undefined;
    items: ReadonlyArray<AreaNavListGroupItem>;
}
export type AreaNavListGroupItem = AreaNavListLinkItem | AreaNavListLinkPlaceholder;
export type AreaNavListItem = Spacer | Divider | AreaNavListLinkItem | AreaNavListLinkPlaceholder | AreaNavListGroup;
type Spacer = "spacer";
type Divider = "divider";
interface AreaNavListLinkItem {
    href: DesignSystemLinkHref;
    showLinkAsActive?: ShowLinkAsActive;
    label: string;
    accessibleName?: string;
    onClick?: MouseEventHandler<Element>;
    icon?: React.ReactNode;
}
interface AreaNavListLinkPlaceholder {
    placeholderLabel: string;
}
export function AreaNavList({ listItems, onLinkClicked }: AreaNavListProps) {
    return (<nav className={areaNavListStyles}>
            <ul className={areaNavListUlStyles}>
                {listItems.map((item, itemIndex) => (<AreaNavListItem key={itemIndex} item={item} onLinkClicked={onLinkClicked}/>))}
            </ul>
        </nav>);
}
function AreaNavListItem({ item, onLinkClicked }: {
    item: AreaNavListItem;
    onLinkClicked?: () => void;
}) {
    if (isSpacer(item)) {
        return <div className={spacerStyles}></div>;
    }
    if (isDivider(item)) {
        return (<div className={dividerStyles}>
                <Divider />
            </div>);
    }
    else if (isPlaceholderLabel(item)) {
        return <li className={placeholderItemStyles}>{item.placeholderLabel}</li>;
    }
    else if (isGroup(item)) {
        return (<li>
                <div className={listGroupTitleStyles}>{item.heading}</div>
                <ul className={areaNavListUlStyles} aria-label={item.heading}>
                    {item.items.map((linkItem: AreaNavListItem, index) => (<AreaNavListItem key={index} item={linkItem} onLinkClicked={onLinkClicked}/>))}
                </ul>
            </li>);
    }
    else {
        const onClick = (ev: React.MouseEvent<Element>) => {
            onLinkClicked?.();
            item.onClick?.(ev);
        };
        return (<li>
                <AreaNavListLink label={item.label} href={item.href} showLinkAsActive={item.showLinkAsActive} accessibleName={item.accessibleName} onClick={onClick} icon={item.icon}/>
            </li>);
    }
}
function isPlaceholderLabel(item: AreaNavListItem): item is AreaNavListLinkPlaceholder {
    return typeof item === "object" && "placeholderLabel" in item;
}
function isGroup(item: AreaNavListItem): item is AreaNavListGroup {
    return typeof item === "object" && "items" in item;
}
function isSpacer(item: AreaNavListItem): item is Spacer {
    return item === "spacer";
}
function isDivider(item: AreaNavListItem): item is Divider {
    return item === "divider";
}
const areaNavListStyles = css({
    width: "12.5rem",
    height: "100%",
    padding: `${space["32"]} ${space["16"]} ${space["24"]} ${space["16"]}`,
    background: themeTokens.color.background.primary.transparent,
    overflowY: "auto",
    // to hide the scrollbar on the right side of the panel, while scrolling is enabled
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
        width: 0,
        height: 0,
    },
});
const areaNavListUlStyles = css({
    ...resetStyles.ul,
});
const listGroupTitleStyles = css({
    font: text.regular.bold.xSmall,
    color: themeTokens.color.text.tertiary,
    textTransform: "uppercase",
    padding: `${space["4"]} ${space["8"]}`,
});
const spacerStyles = css({
    height: "1rem",
});
const dividerStyles = css({
    margin: `${space["24"]} 0`,
});
const placeholderItemStyles = css({
    font: text.regular.default.medium,
    fontStyle: "italic",
    padding: `${space["4"]} ${space["8"]}`,
});
