import { css, cx } from "@emotion/css";
import { RadioGroup } from "@material-ui/core";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import type { FormFieldProps } from "../FormFieldProps";
import { FormFieldTitle } from "../FormFieldTitle";
interface RadioButtonGroupContextValue {
    value: string;
    autoFocus: boolean;
}
export const RadioButtonGroupContext = React.createContext<RadioButtonGroupContextValue | undefined>(undefined);
export interface RadioButtonGroupProps<T extends string> extends FormFieldProps<T> {
    /**
     * Controls whether the radio options should be displayed horizontally.
     */
    horizontal?: boolean;
    /**
     * Controls whether the selected radio button within the group should be automatically focused.
     */
    autoFocus?: boolean;
    /**
     * The title to display above the radio button group.
     */
    title?: string;
    /**
     * The error to display under the radio button group.
     */
    error?: string;
    /**
     * The classname to apply to the radio button group.
     *
     * @deprecated Please avoid using this. This property will be removed in the near future.
     */
    className?: string;
    /**
     * Controls whether or not to include the top and bottom margins around the radio button group.
     */
    noMargin?: boolean;
    /**
     * The accessible name of the radio button group.
     */
    accessibleName?: string;
    children: React.ReactNode;
}
export function RadioButtonGroup<T extends string>({ className, value, onChange, title, children, error, horizontal, noMargin, accessibleName, autoFocus }: RadioButtonGroupProps<T>) {
    const [showExternalError, setShowExternalError] = React.useState(true);
    const [previousError, setPreviousError] = React.useState(error);
    if (error !== previousError) {
        setPreviousError(error);
        setShowExternalError(true);
    }
    const handleChange = React.useCallback((event: React.ChangeEvent, value: string) => {
        setShowExternalError(false);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        onChange?.(value as T);
    }, [onChange]);
    const groupContextValue = React.useMemo(() => ({
        value,
        autoFocus: autoFocus ?? false,
    }), [value, autoFocus]);
    /**
     * Some usages of the RadioButtonGroup pass in value as "undefined" on first render, then switches to a proper value when loaded.
     * In MUI 0, this would result in the RadioButtonGroup value defaulting to the first option's value, then switching to whatever value was loaded.
     * In MUI 4, this doesn't work as it chooses between controlled and uncontrolled mode depending on whether the value is undefined, and throws an error if it changes.
     * Ideally consumers would be updated to always pass in a valid value, however a lot of process editor step properties are typed as string, but can be undefined so it's difficult to identify probelmatic usages.
     * Using the key is a hacky workaround to forcibly remount the RadioGroup if the controlled mode changes and the value becomes selected.
     */
    const key = value === undefined ? "uncontrolled" : "controlled";
    return (<RadioButtonGroupContext.Provider value={groupContextValue}>
            <div key={key} className={cx(containerStyles, { [containerTopMarginStyles]: !noMargin })}>
                {title && <FormFieldTitle title={title}/>}
                <RadioGroup aria-label={accessibleName} className={cx(radioGroupStyles, { [radioGroupHorizontalStyles]: horizontal }, className)} onChange={handleChange} value={value}>
                    {children}
                </RadioGroup>
                {showExternalError && error && <div className={errorTextStyles}>{error}</div>}
            </div>
        </RadioButtonGroupContext.Provider>);
}
const muiFormGroupClasses = {
    root: "MuiFormGroup-root",
};
const containerStyles = css({
    width: "100%",
    marginBottom: space[16],
});
const containerTopMarginStyles = css({ marginTop: space[16] });
const radioGroupStyles = css({
    [`&.${muiFormGroupClasses.root}`]: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
    },
    // This is not ideal as it is has knowledge about how RadioGroup is used
    // This adds space on the left of non-radiobutton elements (e.g. <Note />) so they line up with the radiobutton label
    "> :not(.MuiFormControlLabel-root)": {
        marginLeft: space[32],
    },
});
const radioGroupHorizontalStyles = css({
    [`&.${muiFormGroupClasses.root}`]: {
        flexDirection: "row",
        gap: space[48],
    },
});
const errorTextStyles = css({
    marginTop: space[4],
    font: text.regular.default.xSmall,
    color: themeTokens.color.text.danger,
});
