import { css } from "@emotion/css";
import { borderRadius, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { AriaAttributes } from "react";
import React, { forwardRef } from "react";
import { resetStyles } from "../../utils/resetStyles";
export interface NavIconButtonProps extends NavIconButtonAriaAttributes {
    onClick?: React.MouseEventHandler;
    accessibleName: string;
    icon: React.ReactNode;
}
type NavIconButtonAriaAttributes = Pick<AriaAttributes, "aria-controls" | "aria-haspopup" | "aria-expanded">;
export const NavIconButton = forwardRef<HTMLButtonElement, NavIconButtonProps>(({ onClick, accessibleName, icon, ...props }, ref) => {
    const ariaAttributes: NavIconButtonAriaAttributes = {
        ["aria-controls"]: props["aria-controls"],
        ["aria-haspopup"]: props["aria-haspopup"],
        ["aria-expanded"]: props["aria-expanded"],
    };
    return (<button className={navIconButtonStyles} onClick={onClick} aria-label={accessibleName} ref={ref} {...ariaAttributes}>
            {icon}
        </button>);
});
NavIconButton.displayName = "NavIconButton";
const navIconButtonStyles = css({
    ...resetStyles.button,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "2.25rem",
    minWidth: "2.25rem",
    borderRadius: borderRadius.small,
    color: themeTokens.color.navButton.icon.default,
    background: "transparent",
    ":focus-visible": {
        color: themeTokens.color.navButton.icon.hover,
        background: themeTokens.color.navButton.background.hover,
        boxShadow: themeTokens.shadow.focused,
        zIndex: 1,
    },
    ":hover": {
        color: themeTokens.color.navButton.icon.hover,
        background: themeTokens.color.navButton.background.hover,
    },
    ":active": {
        color: themeTokens.color.navButton.icon.pressed,
        background: themeTokens.color.navButton.background.pressed,
    },
    "&[aria-expanded=true]": {
        color: themeTokens.color.navButton.icon.active,
        background: themeTokens.color.navButton.background.active,
    },
});
