import { css } from "@emotion/css";
import { Collapse } from "@material-ui/core";
import { borderRadius, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React, { useEffect, useState } from "react";
import { CircleCheckIcon } from "../Icon/CircleCheckIcon";
import { CircleInfoIcon } from "../Icon/CircleInfoIcon";
export type InlineSnackbarVariations = "neutral" | "success";
export interface InlineSnackbarProps {
    /**
     * Controls the style of the InlineSnackbar.
     */
    variant: InlineSnackbarVariations;
    /**
     * Controls whether the InlineSnackbar is being shown.
     */
    show: boolean;
    /**
     * Specifies how long the InlineSnackbar will remain open, in ms. After the time has elapsed, the InlineSnackbar will invoke the `onClose` callback.
     */
    autoHideDuration?: number;
    /**
     * The content or message that will be displayed within the InlineSnackbar.
     */
    content: string | React.ReactNode;
    /**
     * The callback is invoked when the `autoHideDuration` has elapsed. This callback should be used to change the `open` state, controlled in a parent component.
     */
    onClose?(): void;
}
export function InlineSnackbar({ variant, show, autoHideDuration, content, onClose }: InlineSnackbarProps) {
    const [autoHideTimeout, setAutoHideTimeout] = useState<ReturnType<typeof setTimeout> | undefined>(undefined);
    useEffect(() => {
        if (autoHideTimeout)
            clearTimeout(autoHideTimeout);
        if (show && autoHideDuration && onClose) {
            setAutoHideTimeout(setTimeout(() => onClose(), autoHideDuration));
        }
        return () => {
            clearTimeout(autoHideTimeout);
        };
        // Excludes autoHideTimeout to prevent endless looping updates
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoHideDuration, onClose, show]);
    const formattedContent = (<div className={styles.content}>
            <span className={styles.icon}>{icon(variant)}</span>
            <span className={styles.text}>{content}</span>
        </div>);
    return (<Collapse in={show}>
            <div className={styles.snackbar(variant)}>{typeof content === "string" ? formattedContent : content}</div>
        </Collapse>);
}
const icon = (variant: InlineSnackbarVariations) => {
    switch (variant) {
        case "neutral":
            return <CircleInfoIcon />;
        case "success":
            return <CircleCheckIcon size={20}/>;
    }
};
const backgroundColor = (variant: InlineSnackbarVariations) => {
    switch (variant) {
        case "neutral":
            return themeTokens.color.background.inverse.neutral;
        case "success":
            return themeTokens.color.background.inverse.success;
    }
};
const styles = {
    snackbar: (variant: InlineSnackbarVariations) => css({
        backgroundColor: backgroundColor(variant),
        padding: `${space[8]} ${space[16]}`,
        borderRadius: borderRadius.small,
    }),
    content: css({
        display: "flex",
        alignItems: "center",
    }),
    icon: css({
        paddingRight: space[6],
        color: themeTokens.color.icon.inverse,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }),
    text: css({
        font: text.regular.bold.medium,
        color: themeTokens.color.icon.inverse,
    }),
};
