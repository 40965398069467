import { css } from "@emotion/css";
import { space, text } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { ActionButton, ActionButtonType } from "../Button";
import { IconButton } from "../IconButton";
import { SimpleMenu, useMenuState } from "../Menu";
import type { SimpleMenuItem } from "../MenuItems";
export interface PaginationProps {
    /**
     * The label for the pagination controls.
     */
    label: string;
    /**
     * The total results that have been paginated.
     */
    totalResults: number;
    /**
     * The current page being displayed.
     */
    currentPage: number;
    /**
     * The range of options to present for the number of items per page.
     */
    itemsPerPageOptions: readonly number[];
    /**
     * The selected number of items per page.
     */
    selectedItemsPerPage: number;
    /**
     * The callback to run when the page is changed.
     */
    onPageChange: (page: number) => void;
    /**
     * The callback to run when the page size is changed.
     */
    onPageSizeChange: (pageSize: number) => void;
}
export function Pagination({ label, totalResults, currentPage, itemsPerPageOptions, selectedItemsPerPage, onPageChange, onPageSizeChange }: PaginationProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const pageStart = Math.min((currentPage - 1) * selectedItemsPerPage + 1, totalResults);
    const pageEnd = Math.min(currentPage * selectedItemsPerPage, totalResults);
    const totalResultsText = `${pageStart} - ${pageEnd} of ${totalResults} ${label}`;
    const previousIsDisabled = currentPage === 1;
    const nextIsDisabled = currentPage * selectedItemsPerPage >= totalResults;
    const hasPageSizeOptions = itemsPerPageOptions.length > 0;
    const menuItems: SimpleMenuItem[] = [];
    menuItems.push({
        type: "information",
        content: <span className={resultsPerPageMenuItemStyles}>{label} per page</span>,
    });
    itemsPerPageOptions.map((itemsPerPage) => {
        menuItems.push({
            type: "button",
            label: `${itemsPerPage}`,
            onClick: () => onPageSizeChange(itemsPerPage),
            isSelected: itemsPerPage === selectedItemsPerPage,
        });
    });
    return (<div className={paginationWrapper}>
            <div className="tracker-pagination">
                <ActionButton label={totalResultsText} onClick={hasPageSizeOptions ? (e) => openMenu(e) : undefined} type={ActionButtonType.Ternary} menuButtonAttributes={buttonAriaAttributes}/>
                {hasPageSizeOptions && <SimpleMenu compact={true} menuState={menuState} items={menuItems} accessibleName={"Change page size"}/>}
            </div>
            <div className={iconButtonWrapper}>
                <IconButton onClick={() => onPageChange(currentPage - 1)} icon="ChevronLeft" accessibleName="Previous" disabled={previousIsDisabled} disableRipple={true}/>
            </div>
            <div className={iconButtonWrapper}>
                <IconButton onClick={() => onPageChange(currentPage + 1)} icon="ChevronRight" accessibleName="Next" disabled={nextIsDisabled} disableRipple={true}/>
            </div>
        </div>);
}
const resultsPerPageMenuItemStyles = css({
    font: text.regular.bold.medium,
});
const paginationWrapper = css({
    display: "flex",
    alignItems: "center",
    gap: space["2"],
});
const iconButtonWrapper = css({
    padding: space["6"],
});
